import * as React from 'react'

import { SectionType } from '@/types/SectionType'
import { SectionWrap } from '@/components/sections/SectionWrap'
import { NewsCard } from '@/components/Cards/ArticleCards'
import { NewsInformationFragment } from '@root/types/graphql-types'
import { useImage } from '@/hooks/useImage'
import { graphql } from 'gatsby'

interface Props extends SectionType {
    newsArticles: NewsInformationFragment[]
}

export const NewsSection: React.FC<Props> = (props) => {
    const { getImageProps, Image } = useImage()
    const { newsArticles, children } = props
    return (
        <SectionWrap {...props}>
            <ul className="p-section__content p-list">
                {(newsArticles.length !== 0 &&
                    newsArticles.map((article) => (
                        <li className="p-list__content">
                            <NewsCard
                                key={article.slug}
                                newsInfo={article}
                                href={`/news/${article.slug}`}
                                Icon={
                                    article.newsIcon && (
                                        <Image
                                            {...getImageProps(
                                                article.newsIcon
                                                    .gatsbyImageData,
                                                {
                                                    alt: `${article.newsTitle}のアイコン`,
                                                }
                                            )}
                                        />
                                    )
                                }
                            />
                        </li>
                    ))) || (
                    <p className="c-notice--nothing">投稿がありません</p>
                )}
            </ul>
            {children}
        </SectionWrap>
    )
}

export const query = graphql`
    fragment NewsInformation on ContentfulNews {
        id
        newsIcon {
            gatsbyImageData(resizingBehavior: FILL, height: 300, width: 300)
        }
        newsTitle
        newsTags
        updatedAt(formatString: "YYYY/MM/DD")
        slug
    }
`
