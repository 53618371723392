import * as React from "react"

import { SectionType } from "@/types/SectionType"

import { SectionWrap } from "@/components/sections/SectionWrap"
import { LivesCard } from "@/components/Cards/ArticleCards"
import { LivesInformationFragment } from "@root/types/graphql-types"
import { useImage } from "@/hooks/useImage"
import { graphql } from "gatsby"

interface Props extends SectionType {
    liveArticles: LivesInformationFragment[]
}

export const LivesSection: React.FC<Props> = (props) => {
    const { getImageProps, Image } = useImage()
    const { liveArticles, children } = props
    return (
        <SectionWrap {...props}>
            <ul className="p-section__content p-list">
                {liveArticles.length !== 0 ? (
                    liveArticles.map((article) => (
                        <li className="p-list__content">
                            <LivesCard
                                key={article.slug}
                                liveInfo={article}
                                Icon={
                                    article.liveImages?.[0] && (
                                        <Image
                                            {...getImageProps(
                                                article.liveImages[0]
                                                    .gatsbyImageData,
                                                {
                                                    alt: `${article.liveTitle}のアイコン`,
                                                }
                                            )}
                                        />
                                    )
                                }
                                href={`/lives/${article.slug}`}
                            />
                        </li>
                    ))
                ) : (
                    <p className="c-notice--nothing">投稿がありません</p>
                )}
            </ul>
            {children}
        </SectionWrap>
    )
}

export const query = graphql`
    fragment LivesInformation on ContentfulLives {
        liveActs
        liveDate
        liveImages {
            gatsbyImageData(width: 300, height: 300, resizingBehavior: FILL)
        }
        liveIsSoldOut
        livePlaceName
        liveTime
        liveTitle
        slug
        id
        description
    }
`
