import * as React from "react"

// import { DotLoading } from '@/components/icon/dotLoading';

const { useState } = React

type Props = {
    title: string
    youtubeId: string
    Image: React.ReactElement
    addClass?: string
}

export const Video: React.FC<Props> = ({
    title,
    youtubeId,
    Image,
    addClass,
}) => {
    const [isUserClick, setIsUserClick] = useState(false)

    return (
        <div className={`c-video${addClass || ""}`}>
            {/* <div className={` c-video__loader ${isLoading? 'is-active': ''}`}>
                <DotLoading/>
            </div> */}
            {(isUserClick && (
                <iframe
                    className="c-video__content"
                    src={`//www.youtube.com/embed/${youtubeId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            )) || (
                <div
                    className="c-video__dammy"
                    onClick={(e) => {
                        setIsUserClick(true)
                    }}
                >
                    <div className="c-video__dammy-title-wrap">
                        <div className="c-video__dammy-title ">{title}</div>
                    </div>
                    <div className="c-video__dammy-icon">
                        <i className="fas fa-play c-video__dammy-icon-play"></i>
                    </div>
                    {Image}
                </div>
            )}
        </div>
    )
}
