import { Top } from "@/components/pages/Top"
import { graphql } from "gatsby"

export default Top

export const query = graphql`
    query TopPage {
        contentfulSite {
            siteHeroImage {
                gatsbyImageData(resizingBehavior: FILL)
            }
            siteMv {
                title
                description
                id
                gatsbyImageData(resizingBehavior: FILL)
            }
            siteMvList {
                title
                description
                id
                gatsbyImageData(resizingBehavior: FILL)
            }
            sitePickupArticles

            ...ProfileInformation
        }
        allContentfulLives(
            # 次ライブの特定が行えるように余分に取得
            limit: 15
            sort: { fields: liveDate, order: DESC }
            filter: { slug: { ne: "dummy" } }
        ) {
            edges {
                node {
                    ...LivesInformation
                }
            }
        }
        allContentfulNews(
            limit: 3
            sort: { order: DESC, fields: updatedAt }
            filter: { slug: { ne: "dummy" } }
        ) {
            edges {
                node {
                    ...NewsInformation
                }
            }
        }
        allContentfulMusics(
            limit: 5
            sort: { order: DESC, fields: updatedAt }
            filter: { slug: { ne: "dummy" } }
        ) {
            nodes {
                ...MusicsInformation
            }
        }
        allContentfulGoods(
            limit: 5
            sort: { order: DESC, fields: updatedAt }
            filter: { slug: { ne: "dummy" } }
        ) {
            nodes {
                ...GoodsInformation
            }
        }
    }
`
