import React, { ComponentType } from 'react'
import { _GoodsCardLayout } from '@/components/Cards/GoodsCards/_GoodsCardLayout'
import { opAbridgementText } from '@/functions/opAbridgementText'

type Props = {
    title: string
    price: number | 'unknown'
    isConcertExclusive?: boolean
} & Omit<React.ComponentProps<typeof _GoodsCardLayout>, 'children'>

export const GoodsCard: React.FC<Props> = (props) => {
    const { title, price, isConcertExclusive } = props
    return (
        <_GoodsCardLayout {...props}>
            <h3 className="p-menu-card__title">{title}</h3>
            <p className="p-menu-card__sub">
                {isConcertExclusive && (
                    <div className="p-menu-card__sub-left">
                        <span className="c-tag--red">会場限定販売</span>
                    </div>
                )}
                <div className="p-menu-card__sub-right">
                    <span>
                        {typeof price === 'number'
                            ? price.toLocaleString()
                            : '?'}
                        円
                    </span>
                </div>
            </p>
        </_GoodsCardLayout>
    )
}
