import * as React from "react"

import { SectionType } from "@/types/SectionType"

import { SectionWrap } from "@/components/sections/SectionWrap"
import { GallerySlider } from "@/components/Sliders"
import { graphql } from "gatsby"
import { MusicsInformationFragment } from "@root/types/graphql-types"
import { useImage } from "@/hooks/useImage"
import { MusicCard } from "../Cards/GoodsCards"

interface Props extends SectionType {
    musics: MusicsInformationFragment[]
}

export const MusicsSection: React.FC<Props> = (props) => {
    const { getImageProps, Image } = useImage()
    const { musics, children } = props
    return (
        <SectionWrap {...props}>
            {musics.length > 0 ? (
                <GallerySlider
                    className="p-section__content"
                    slides={musics.map((m) => (
                        <MusicCard
                            title={m.musicTitle || ""}
                            href={`/musics/${m.slug}`}
                            Image={
                                <Image
                                    {...getImageProps(
                                        m.musicCDImage?.gatsbyImageData
                                    )}
                                />
                            }
                            releaseDate={m.musicReleaseDate}
                            musicType={m.musicType || "unknown"}
                            joiningArtistName={
                                m.musicJoiningArtistName || undefined
                            }
                        />
                    ))}
                />
            ) : (
                <p className="c-notice--nothing">作品がありません</p>
            )}
            {children}
        </SectionWrap>
    )
}

export const query = graphql`
    fragment MusicsInformation on ContentfulMusics {
        id
        slug
        musicType
        musicTitle
        musicReleaseDate
        musicCDImage {
            gatsbyImageData(resizingBehavior: FILL, height: 300, width: 300)
        }
        musicJoiningArtistName
    }
`
