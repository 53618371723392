import React from 'react'
import { createToken } from '@/functions/createToken'
import { SectionType } from '@/types/SectionType'
import { ProfileInformationFragment } from '@root/types/graphql-types'
import { useImage } from '@/hooks/useImage'
import { Document } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql } from 'gatsby'
import { SectionWrap } from './SectionWrap'

type Props = {
    profile: ProfileInformationFragment
} & SectionType

export const ProfileSection: React.FC<Props> = (props) => {
    const { profile, children } = props
    const createProfileContent = (content: string): Document | undefined => {
        try {
            return JSON.parse(content)
        } catch (e) {
            console.error(e)
            console.error(content)
            return undefined
        }
    }
    const profileContent = createProfileContent(
        profile.siteProfileContent?.raw || ''
    )
    const { getImageProps, Image } = useImage()
    return (
        <SectionWrap {...props}>
            <div className="p-section__content">
                <div className="p-profile">
                    <div className="p-profile__img">
                        {profile.siteProfileImage ? (
                            <Image
                                {...getImageProps(
                                    profile.siteProfileImage.gatsbyImageData,
                                    {
                                        alt: 'プロフィール画像',
                                    }
                                )}
                            />
                        ) : (
                            <p>プロフィール画像を設定してください</p>
                        )}
                    </div>
                    <div className="p-profile__content">
                        <ul className="p-profile__members">
                            {profile.siteProfileMember?.map((member) => (
                                <React.Fragment key={createToken()}>
                                    <li className="p-profile__member c-member">
                                        <span className="c-member__part">
                                            {member?.split(',')[0]}
                                        </span>
                                        <span className="c-member__name">
                                            {member?.split(',')[1]}
                                        </span>
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                        <div className="p-profile__introduction">
                            {profileContent &&
                                documentToReactComponents(profileContent)}
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </SectionWrap>
    )
}

export const query = graphql`
    fragment ProfileInformation on ContentfulSite {
        siteProfileContent {
            raw
        }
        siteProfileImage {
            gatsbyImageData(resizingBehavior: FILL)
        }
        siteProfileMember
    }
`
