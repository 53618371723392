import * as React from "react"

import { SectionType } from "@/types/SectionType"

import { SectionWrap } from "@/components/sections/SectionWrap"
import { LivesCard, NewsCard } from "@/components/Cards/ArticleCards"
import { useImage } from "@/hooks/useImage"
import {
    LivesInformationFragment,
    NewsInformationFragment,
} from "@root/types/graphql-types"

interface Props extends SectionType {
    articles: {
        news: NewsInformationFragment[]
        lives: LivesInformationFragment[]
        nextLives: LivesInformationFragment[]
    }
}

export const PickupSection: React.FC<Props> = (props) => {
    const { getImageProps, Image } = useImage()
    const { articles, children } = props
    const { news, lives, nextLives } = articles
    return (
        <>
            {(articles.news.length > 0 ||
                articles.lives.length > 0 ||
                articles.nextLives.length > 0) && (
                <SectionWrap {...props}>
                    {/** NEXT LIVE LIST */}
                    {nextLives && (
                        <ArticleList
                            Cards={nextLives.map((nextLive) => (
                                <LivesCard
                                    key={nextLive.slug}
                                    liveInfo={nextLive}
                                    ribbon={{
                                        color: "red",
                                        text: "NEXT LIVE",
                                    }}
                                    href={`/lives/${nextLive.slug}`}
                                    addClass="p-list__content"
                                    Icon={
                                        <Image
                                            {...getImageProps(
                                                nextLive.liveImages?.[0]
                                                    ?.gatsbyImageData,
                                                {
                                                    alt: `${nextLive.liveTitle}のアイコン`,
                                                }
                                            )}
                                        />
                                    }
                                />
                            ))}
                        />
                    )}

                    {/** NEWS LIST */}
                    <ArticleList
                        Cards={news.map((n) => (
                            <NewsCard
                                key={n.slug}
                                newsInfo={n}
                                ribbon={{
                                    color: "blue",
                                    text: "NEWS",
                                }}
                                href={`/news/${n.slug}`}
                                addClass="p-list__content"
                                Icon={
                                    <Image
                                        {...getImageProps(
                                            n.newsIcon?.gatsbyImageData,
                                            {
                                                alt: `${n.newsTitle}のアイコン`,
                                            }
                                        )}
                                    />
                                }
                            />
                        ))}
                    />

                    {/** LIVES LIST */}
                    <ArticleList
                        Cards={lives.map((l) => (
                            <LivesCard
                                key={l.slug}
                                liveInfo={l}
                                ribbon={{
                                    color: "red",
                                    text: "LIVE",
                                }}
                                href={`/lives/${l.slug}`}
                                addClass="p-list__content"
                                Icon={
                                    <Image
                                        {...getImageProps(
                                            l.liveImages?.[0]?.gatsbyImageData,
                                            {
                                                alt: `${l.liveTitle}のアイコン`,
                                            }
                                        )}
                                    />
                                }
                            />
                        ))}
                    />
                    {children}
                </SectionWrap>
            )}
        </>
    )
}

type ArticleList = {
    Cards: React.ReactElement[]
}

const ArticleList: React.FC<ArticleList> = ({ Cards }) => {
    return (
        <>
            {Cards.length > 0 && (
                <div>
                    <ul className="p-section__content p-list">
                        {Cards.map((Card) => (
                            <li>{Card}</li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}
