import * as React from "react"

import { SectionType } from "@/types/SectionType"
import { SectionWrap } from "@/components/sections/SectionWrap"
import { Video } from "@/components/Video"
import { graphql } from "gatsby"
import { VideosInformationFragment } from "@root/types/graphql-types"
import { useImage } from "@/hooks/useImage"

interface Props extends SectionType {
    videos?: VideosInformationFragment[]
    videoWrapClass?: string
}

export const VideosSection: React.FC<Props> = (props) => {
    const { videos, videoWrapClass, children } = props
    const { getImageProps, Image } = useImage()
    return (
        <SectionWrap {...props}>
            <div className="p-section__content flex flex-col gap-10 rmd:w-11/12 ml-auto mr-auto">
                {videos ? (
                    videos.map((video) => (
                        <div className={`p-videos`} key={video.videosVideo?.id}>
                            <Video
                                title={video.videosVideo?.title || "unknown"}
                                youtubeId={video.videosVideo?.description || ""}
                                Image={
                                    <Image
                                        {...getImageProps(
                                            video.videosVideo?.gatsbyImageData
                                        )}
                                    />
                                }
                            />
                        </div>
                    ))
                ) : (
                    <p className="c-notice--nothing">動画がありません</p>
                )}
            </div>
            {children}
        </SectionWrap>
    )
}

export const query = graphql`
    fragment VideosInformation on ContentfulVideos {
        videosVideo {
            title
            description
            id
            gatsbyImageData(resizingBehavior: FILL)
        }
    }
`
