import * as React from "react"

import { Head } from "@/components/Head"
import { GlobalLayout } from "@/components/GlobalLayout"
import BtnNagamaru from "@/components/btn/BtnNagamaru"
import { HeroImage } from "@/components/HeroImage"

import type {
    LivesInformationFragment,
    NewsInformationFragment,
    TopPageQuery,
} from "@root/types/graphql-types"
import { TitleWithBgImg } from "@/components/TitleWithBgImg"

import {
    GoodsSection,
    LivesSection,
    MusicsSection,
    NewsSection,
    PickupSection,
    ProfileSection,
    VideosSection,
} from "@/components/sections"
import { getNextLives, getPickupArticles, getProfileProps } from "./Top.func"
import { useImage } from "@/hooks/useImage"
import { PageProps } from "gatsby"

type Props = PageProps<TopPageQuery>

export const Top: React.FC<Props> = (props) => {
    const { data } = props
    const { getImageProps, Image } = useImage()

    const ReadMoreButton: React.FC<{ link: string }> = ({ link }) => (
        <BtnNagamaru addClass=" p-section__link-btn" link={link}>
            もっと見る
        </BtnNagamaru>
    )

    return (
        <GlobalLayout
            topPage
            Head={<Head />}
            HeroImage={
                data.contentfulSite?.siteHeroImage?.gatsbyImageData && (
                    <HeroImage
                        Image={
                            <Image
                                {...getImageProps(
                                    data.contentfulSite?.siteHeroImage
                                        ?.gatsbyImageData,
                                    {
                                        alt: "ヒーローイメージ",
                                    }
                                )}
                            />
                        }
                    />
                )
            }
        >
            <PickupSection
                articles={{
                    news: getPickupArticles<NewsInformationFragment>(
                        data.allContentfulNews.edges.map((e) => e.node),
                        data.contentfulSite?.sitePickupArticles || undefined
                    ),
                    lives: getPickupArticles<LivesInformationFragment>(
                        data.allContentfulLives.edges.map((e) => e.node),
                        data.contentfulSite?.sitePickupArticles || undefined
                    ),
                    nextLives: getNextLives(
                        data.allContentfulLives.edges.map((e) => e.node)
                    ),
                }}
                Title={
                    <TitleWithBgImg
                        title="PICK UP"
                        // addClass="--pickup"
                    />
                }
            />
            <NewsSection
                addClass=" u-mt-5"
                newsArticles={data.allContentfulNews.edges.map((e) => e.node)}
                Title={<TitleWithBgImg title="NEWS" />}
            >
                <ReadMoreButton link={"/news"} />
            </NewsSection>

            {/** graphqlで余分に取得しているため、sliceで取得量を指定 */}
            <LivesSection
                liveArticles={data.allContentfulLives.edges
                    .map((e) => e.node)
                    .slice(0, 3)}
                Title={<TitleWithBgImg title="LIVES" />}
            >
                <ReadMoreButton link={"/lives"} />
            </LivesSection>

            <VideosSection
                videos={
                    data.contentfulSite?.siteMvList
                        ? data.contentfulSite.siteMvList.map((siteMv) => ({
                              videosVideo: siteMv,
                          }))
                        : undefined
                }
                Title={<TitleWithBgImg title="MV" />}
            >
                <ReadMoreButton link={"/videos"} />
            </VideosSection>

            <ProfileSection
                profile={{ ...getProfileProps(data) }}
                Title={<TitleWithBgImg title="PROFILE" />}
            />

            <MusicsSection
                addClass="--gallery"
                musics={data.allContentfulMusics?.nodes}
                Title={<TitleWithBgImg title="MUSICS" />}
                bgColor="aliceblue"
            >
                <ReadMoreButton link={"/musics"} />
            </MusicsSection>

            <GoodsSection
                addClass="--gallery"
                goodsInfos={data.allContentfulGoods?.nodes}
                Title={<TitleWithBgImg title="GOODS" />}
                bgColor="ivory"
            >
                <ReadMoreButton link={"/goods"} />
            </GoodsSection>
        </GlobalLayout>
    )
}
