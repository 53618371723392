/**
 * 第二引数に指定した文字数の文字列を出力する。それ以上なら省略する
 * 小文字英数字が含まれていたら許容文字数を+3する
 */ 
export const opAbridgementText = (str: string, length: number): string => {
    if(/[a-z0-9]/.test(str)){
        length += 3;
    }
    if(str.length <= length) {
        return str;
    }

    let resultStr: string = '';
    let i: number = 0;

    for(let char of str.split('')) {
        if(length <= i) {
            break;
        }
        i++;
        resultStr += char;
    }
    return `${resultStr}...`;
}