import React from 'react'
import { Link } from 'gatsby'

type Props = {
    href?: string
    target?: '_blank'
    children: React.ReactNode
    Image: React.ReactElement
    onClick?: (e: React.MouseEvent) => void
    className?: string
}

export const _GoodsCardLayout: React.FC<Props> = ({
    href,
    target,
    Image,
    children,
    className,
}) => {
    const CardContent = (
        <>
            <figure className="p-menu-card__img">{Image}</figure>
            <div className="p-menu-card__content">{children}</div>
        </>
    )

    return href !== undefined ? (
        <Link
            to={href}
            className={`p-menu-card ${className || ''}`}
            target={target}
            rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        >
            {CardContent}
        </Link>
    ) : (
        <div className={`p-menu-card ${className || ''}`}>{CardContent}</div>
    )
}
