import * as React from "react"

import { SectionType } from "@/types/SectionType"

import { SectionWrap } from "@/components/sections/SectionWrap"
import { graphql } from "gatsby"
import { GoodsInformationFragment } from "@root/types/graphql-types"
import { GallerySlider } from "../Sliders"
import { useImage } from "@/hooks/useImage"
import { GoodsCard } from "../Cards/GoodsCards"

interface Props extends SectionType {
    goodsInfos: GoodsInformationFragment[]
}

export const GoodsSection: React.FC<Props> = (props) => {
    const { getImageProps, Image } = useImage()
    const { goodsInfos, children } = props
    return (
        <SectionWrap {...props}>
            {goodsInfos.length > 0 ? (
                <GallerySlider
                    className="p-section__content"
                    slides={goodsInfos.map((g) => (
                        <GoodsCard
                            title={g.goodsTitle || ""}
                            href={`/goods/${g.slug}`}
                            Image={
                                <Image
                                    {...getImageProps(
                                        g.goodsIcon?.gatsbyImageData
                                    )}
                                />
                            }
                            price={
                                g.goodsPrice ? Number(g.goodsPrice) : "unknown"
                            }
                            isConcertExclusive={g.goodsUrl !== undefined}
                            className={"max-w-full"}
                        />
                    ))}
                />
            ) : (
                <p className="c-notice--nothing">商品がありません</p>
            )}

            {children}
        </SectionWrap>
    )
}

export const query = graphql`
    fragment GoodsInformation on ContentfulGoods {
        id
        goodsTitle
        goodsPrice
        goodsUrl
        goodsIcon {
            gatsbyImageData(resizingBehavior: FILL, width: 300, height: 300)
        }
        slug
        updatedAt(formatString: "YYYY/MM/DD")
    }
`
