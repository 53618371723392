import { isLiveFinished } from "@/functions/isLiveFinished";
import {
    ContentfulSite,
    LivesInformationFragment,
    ProfileInformationFragment,
    TopPageQuery
} from "@root/types/graphql-types";

/**
 * pickup記事を取得する
 * @param articles 対象記事一覧
 * @param pickupArticles pickup記事のslug一覧
 * @returns pickup記事一覧
 */
export const getPickupArticles = <T>(
    articles?: T[],
    pickupArticles?: ContentfulSite["sitePickupArticles"]
): T[] => {
    if (!articles) {
        return []
    }
    if (!pickupArticles) {
        return []
    }

    return articles.filter((article) => {
        return (
            "slug" in article && pickupArticles.includes((article as any).slug)
        )
    })
}

/**
 * 次の日程のライブ一覧を取得する
 * @param lives 全ライブ一覧
 * @returns 次の日程のライブ一覧
 */
export const getNextLives = (
    lives: LivesInformationFragment[]
): LivesInformationFragment[] => {
    /**
     * 現在日から一番近いライブ日程を返却する
     * @param lives ライブ情報一覧
     * @returns 現在日から一番近いライブ日程
     */
    const getNearestLiveDate = (lives: LivesInformationFragment[]): Date => {
        let nearestLiveDate = new Date(lives[0].liveDate)
        lives
            .filter((live) => !isLiveFinished(new Date(live.liveDate)))
            .forEach((live) => {
                const liveDate = new Date(live.liveDate)
                if (nearestLiveDate.getTime() > liveDate.getTime()) {
                    nearestLiveDate = liveDate
                }
            })

        return nearestLiveDate
    }

    try {
        const nearestLiveDate = getNearestLiveDate(lives)
        return lives.filter(
            (live) =>
                nearestLiveDate.getTime() === new Date(live.liveDate).getTime()
        )
    } catch (e) {
        return []
    }
}

/**
 * プロフィール用propsを取得する
 * @param data トップページクエリ
 * @returns プロフィール用props
 */
export const getProfileProps = (
    data: TopPageQuery
): ProfileInformationFragment => {
    return {
        siteProfileContent: data.contentfulSite?.siteProfileContent,
        siteProfileImage: data.contentfulSite?.siteProfileImage,
        siteProfileMember: data.contentfulSite?.siteProfileMember,
    }
}
