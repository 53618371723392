import React from 'react'
import { _GoodsCardLayout } from '@/components/Cards/GoodsCards/_GoodsCardLayout'
import { opAbridgementText } from '@/functions/opAbridgementText'
import { formatDate } from '@/functions/formatDate'

type Props = {
    title?: string
    joiningArtistName?: string
    releaseDate: string
    musicType: string
} & Omit<React.ComponentProps<typeof _GoodsCardLayout>, 'children'>

export const MusicCard: React.FC<Props> = (props) => {
    const { title, joiningArtistName, musicType, releaseDate } = props

    return (
        <_GoodsCardLayout {...props}>
            {title && (
                <h3 className="p-menu-card__title">
                    {opAbridgementText(
                        `${title}${
                            joiningArtistName ? ' - ' + joiningArtistName : ''
                        }`,
                        12
                    )}
                </h3>
            )}
            <p
                style={{
                    fontSize: '0.8em',
                    textAlign: 'left',
                }}
            >
                <span
                    className="c-tag"
                    style={{
                        padding: '2px 3px',
                        marginRight: '2px',
                    }}
                >
                    {musicType}
                </span>
                <time>{formatDate(new Date(releaseDate), '/')}</time> Release
            </p>
        </_GoodsCardLayout>
    )
}
